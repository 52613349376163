import React from 'react';

import FormGroup from '@mui/material/FormGroup';
import Grid, { GridProps } from '@mui/material/Grid';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import FormLabel from 'src/components/form-label/form-label';
import { DateField } from 'src/components/formik-fields/formik-fields';
import { useNamespace } from 'src/components/lta-membership-edit/helpers';

import * as styles from './lta-membership-dates.module.less';

interface LtaMembershipDatesProps extends GridProps {
  namespace?: string;
  className?: string;
}

const LtaMembershipDates: React.FC<LtaMembershipDatesProps> = ({ namespace = '', ...props }) => {
  const { t } = useTranslation();
  const withNamespace = useNamespace(namespace);
  const { errors, values } = useFormikContext();

  const dateErrors = getIn(errors, namespace);
  const termValues = getIn(values, namespace);

  return (
    <Grid {...props}>
      <Grid container justifyContent="space-between">
        <FormGroup className={styles.dateField}>
          <FormLabel id={withNamespace('startDate')} spacing={{ margins: { xs: 'bottom' } }}>
            {t('start date')}
          </FormLabel>
          <DateField
            id={withNamespace('startDate')}
            name={withNamespace('startDate')}
            value={termValues.startDate}
            datePickerProps={{
              autoComplete: 'off',
            }}
          />
          {dateErrors?.startDate && <FormErrorMessage>{dateErrors?.startDate}</FormErrorMessage>}
        </FormGroup>
        <FormGroup className={styles.dateField}>
          <FormLabel name={withNamespace('endDate')} spacing={{ margins: { xs: 'bottom' } }}>
            {t('grace period end date')}
          </FormLabel>
          <DateField
            id={withNamespace('endDate')}
            name={withNamespace('endDate')}
            value={termValues.endDate}
            datePickerProps={{
              autoComplete: 'off',
            }}
          />
          {dateErrors?.endDate && <FormErrorMessage>{dateErrors?.endDate}</FormErrorMessage>}
        </FormGroup>
      </Grid>
      <FormGroup className={styles.dateField}>
        <FormLabel name={withNamespace('renewsOn')}>{t('renews on date')}</FormLabel>
        <DateField
          id={withNamespace('renewsOn')}
          name={withNamespace('renewsOn')}
          value={termValues.renewsOn}
          datePickerProps={{
            autoComplete: 'off',
          }}
        />
        {dateErrors?.renewsOn && <FormErrorMessage>{dateErrors?.renewsOn}</FormErrorMessage>}
      </FormGroup>
    </Grid>
  );
};

export default LtaMembershipDates;
