import {
  MembershipPackageRenewalPeriod,
  MembershipPackageStatus,
  PaymentMethodAvailable,
  PricingOption,
  ProcessingFeeType,
} from 'src/graphql-types/lta-registration/globalTypes';
import {
  membershipPackage as MembershipPackageResult,
  membershipPackageVariables as MembershipPackageVariables,
} from 'src/graphql-types/lta-registration/membershipPackage';

import {
  MembershipPackageFormValues,
  MembershipPackageTerm,
  MembershipPackageTermDiscountType,
  MembershipPackageTermStatus,
} from './types';

export const getDefaultMembershipPackageTermDiscount = () => ({
  name: '',
  startDate: '',
  endDate: '',
  type: MembershipPackageTermDiscountType.Percentage,
  value: 0,
});

export const getDefaultMembershipPackageTerm = (): MembershipPackageTerm => ({
  name: '',
  startDate: '',
  endDate: '',
  renewsOn: '',
  status: MembershipPackageTermStatus.Public,
  pricing: {
    pricingOption: ProcessingFeeType.FIXED,
    courtCost: 0,
    grassCourtCost: 0,
    courtCap: 0,
  },
});

export const getDefaultMembershipPackage = (): MembershipPackageFormValues => ({
  code: '',
  courtCap: null,
  courtCost: null,
  description: '',
  confirmation: '',
  benefits: '',
  endDate: '',
  gracePeriodDate: '',
  grassCourtCost: null,
  id: '',
  maxPrice: null,
  name: '',
  organisationType: '',
  packageYear: new Date().getFullYear(),
  paymentMethodAvailable: PaymentMethodAvailable.CARD,
  pricingOption: PricingOption.FIXED,
  processingFee: null,
  processingFeeType: ProcessingFeeType.FIXED,
  renewalPeriod: MembershipPackageRenewalPeriod.ANNUAL,
  renewsOn: '',
  startDate: '',
  status: MembershipPackageStatus.PUBLIC,
  type: '',
  terms: [getDefaultMembershipPackageTerm()],
});

export const useNamespace = (namespace?: string) => (name?: string) =>
  namespace && name ? `${namespace}.${name}` : name || '';
