import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorPlus from '@emetworks/ckeditor5-build-classic-plus';
import FormGroup from '@mui/material/FormGroup';
import { Field, getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import FormLabel from 'src/components/form-label/form-label';
import { useNamespace } from 'src/components/lta-membership-edit/helpers';
import { MembershipPackageTypes } from 'src/components/lta-membership-edit/types';
import Select, { Option } from 'src/components/mui-select/mui-select';
import { Body } from 'src/components/typography/typography';
import { MembershipPackageStatus } from 'src/graphql-types/lta-registration/globalTypes';

import { TextInput } from '@clubspark-react/clubspark-react-tools';

import * as styles from './lta-membership-details.module.less';

interface LtaMembershipDetailsProps {
  namespace?: string;
}

const CK_EDITOR_CONFIG = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    // 'underline',
    'strikethrough',
    'subscript',
    'superscript',
    'link',
    'bulletedList',
    'numberedList',
    // 'to-do-list',
    '|',
    'undo',
    'redo',
  ],
  link: {
    decorators: {
      isExternal: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
        },
      },
    },
  },
};

const LtaMembershipDetails: React.FC<LtaMembershipDetailsProps> = ({ namespace = '' }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext();
  const withNamespace = useNamespace();

  const detailValues = getIn(values, namespace);
  const detailErrors = getIn(errors, namespace);

  const statusOptions: Option[] = [
    {
      label: t('public'),
      value: MembershipPackageStatus.PUBLIC,
    },
    { label: t('hidden'), value: MembershipPackageStatus.PRIVATE },
  ];

  const typeOptions: Option[] = [
    {
      label: t('package 1'),
      value: MembershipPackageTypes.Package_1,
    },
    {
      label: t('package 2 free'),
      value: MembershipPackageTypes.Package_2_Free,
    },
    {
      label: t('package 2 paid'),
      value: MembershipPackageTypes.Package_2_Paid,
    },
    {
      label: t('package 2a paid'),
      value: MembershipPackageTypes.Package_2_A_Paid,
    },
    {
      label: t('package 2b'),
      value: MembershipPackageTypes.Package_2_B,
    },
    {
      label: t('package 3'),
      value: MembershipPackageTypes.Package_3,
    },
    {
      label: t('package 3a'),
      value: MembershipPackageTypes.Package_3A,
    },
    {
      label: t('package 3b'),
      value: MembershipPackageTypes.Package_3_B,
    },
    {
      label: t('package 4'),
      value: MembershipPackageTypes.Package_4,
    },
    {
      label: t('package 4b'),
      value: MembershipPackageTypes.Package_4_B,
    },
    {
      label: t('package 5'),
      value: MembershipPackageTypes.Package_5,
    },
  ];

  return (
    <>
      <FormGroup className={styles.nameField}>
        <FormLabel htmlFor={withNamespace('name')} spacing={{ margins: { xxs: 'bottom' } }}>
          {t('name')}
        </FormLabel>
        <Field
          id={withNamespace('name')}
          name={withNamespace('name')}
          disableUnderline
          component={TextInput}
          value={detailValues?.name}
          outlined
        />
        {detailErrors?.name && <FormErrorMessage>{detailErrors?.name}</FormErrorMessage>}
      </FormGroup>
      <FormGroup className={styles.statusField}>
        <FormLabel htmlFor={withNamespace('code')}>{t('code')}</FormLabel>
        <Field
          id={withNamespace('code')}
          name={withNamespace('code')}
          component={TextInput}
          value={detailValues?.code}
          disableUnderline
          outlined
          disabled
        />

        {detailErrors?.code && <FormErrorMessage>{detailErrors?.code}</FormErrorMessage>}
        <Body spacing={{ margins: { xs: 'top' } }} size="sm" light>
          A code for the package which will help you identify it in the management tools (e.g. MT03). This is sometimes
          used as a cost centre code for financial reporting, but it is entirely up to you what you enter
        </Body>
      </FormGroup>
      <FormGroup className={styles.statusField}>
        <FormLabel htmlFor={withNamespace('status')}>{t('status')}</FormLabel>
        <Field
          id={withNamespace('status')}
          name={withNamespace('status')}
          component={Select}
          defaultValue={detailValues?.status || ''}
          options={statusOptions}
          onSelect={(option) => setFieldValue(withNamespace('status'), option.value)}
          disabled
        />
        {detailErrors?.status && <FormErrorMessage>{detailErrors?.status}</FormErrorMessage>}
      </FormGroup>
      <FormGroup className={styles.statusField}>
        <FormLabel htmlFor={withNamespace('type')}>{t('package type')}</FormLabel>
        <Field
          id={withNamespace('type')}
          name={withNamespace('type')}
          component={Select}
          defaultValue={detailValues?.type || ''}
          options={typeOptions}
          onSelect={(option) => setFieldValue(withNamespace('type'), option.value)}
        />
        {detailErrors?.status && <FormErrorMessage>{detailErrors?.status}</FormErrorMessage>}
      </FormGroup>
      <FormGroup className={styles.ckEditor}>
        <FormLabel htmlFor={withNamespace('description')}>{t('description')}</FormLabel>
        <Field
          id={withNamespace('description')}
          name={withNamespace('description')}
          component={CKEditor}
          editor={ClassicEditorPlus}
          config={CK_EDITOR_CONFIG}
          data={detailValues?.description || ''}
          onChange={(_, editor) => setFieldValue(withNamespace('description'), editor.getData())}
        />
        {detailErrors?.description && <FormErrorMessage>{detailErrors?.description}</FormErrorMessage>}
      </FormGroup>
      <FormGroup className={styles.ckEditor}>
        <FormLabel htmlFor={withNamespace('benefits')}>{t('benefits')}</FormLabel>
        <Field
          id={withNamespace('benefits')}
          name={withNamespace('benefits')}
          component={CKEditor}
          editor={ClassicEditorPlus}
          config={CK_EDITOR_CONFIG}
          data={detailValues?.benefits || ''}
          onChange={(_, editor) => setFieldValue(withNamespace('benefits'), editor.getData())}
        />
        {detailErrors?.benefits && <FormErrorMessage>{detailErrors?.benefits}</FormErrorMessage>}
      </FormGroup>
      <FormGroup className={styles.ckEditor}>
        <FormLabel htmlFor={withNamespace('confirmation')}>{t('confirmation')}</FormLabel>
        <Field
          id={withNamespace('confirmation')}
          name={withNamespace('confirmation')}
          component={CKEditor}
          editor={ClassicEditorPlus}
          config={CK_EDITOR_CONFIG}
          data={detailValues?.confirmation || ''}
          onChange={(_, editor) => setFieldValue(withNamespace('confirmation'), editor.getData())}
        />
        {detailErrors?.confirmation && <FormErrorMessage>{detailErrors?.confirmation}</FormErrorMessage>}
      </FormGroup>
    </>
  );
};

export default LtaMembershipDetails;
