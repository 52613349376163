import React from 'react';

import FormGroup from '@mui/material/FormGroup';
import cx from 'classnames';
import { FastField, getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import FormLabel from 'src/components/form-label/form-label';
import { TextField } from 'src/components/formik-fields/formik-fields';
import { useNamespace } from 'src/components/lta-membership-edit/helpers';
import RadioButtonGroup, { Option } from 'src/components/radio-button-group/radio-button-group';
import { PricingOption } from 'src/graphql-types/lta-registration/globalTypes';

import * as styles from './lta-membership-pricing.module.less';

interface LtaMembershipPricingProps {
  namespace?: string;
}

const LtaMembershipPricing: React.FC<LtaMembershipPricingProps> = ({ namespace = '' }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const withNamespace = useNamespace(namespace);
  const pricingValues = getIn(values, namespace);
  const isFixedFee = pricingValues.pricingOption === 'FIXED';

  return (
    <>
      <FormGroup className={cx(styles.priceFieldWidth, styles.priceField)}>
        <FormLabel spacing={{ margins: { xxs: 'bottom' } }} htmlFor={withNamespace('courtCost')}>
          {isFixedFee ? t('fixed fee') : t('cost per non-grass court')}
        </FormLabel>
        <TextField
          id={withNamespace('courtCost')}
          name={withNamespace('courtCost')}
          value={pricingValues.courtCost}
          inputProps={{
            className: cx(styles.numberInput),
            'data-testid': 'court-cost-input',
          }}
          currencySign="£"
          currencyInput
        />
      </FormGroup>
      {!isFixedFee && (
        <>
          <FormGroup className={cx(styles.priceFieldWidth, styles.priceField)}>
            <FormLabel htmlFor={withNamespace('grassCourtCost')}>{t('cost per grass court')}</FormLabel>
            <TextField
              id={withNamespace('grassCourtCost')}
              name={withNamespace('grassCourtCost')}
              value={pricingValues.grassCourtCost}
              inputProps={{
                className: cx(styles.numberInput),
                'data-testid': 'grass-court-cost-input',
              }}
              currencySign="£"
              currencyInput
            />
          </FormGroup>
          <FormGroup className={styles.priceFieldWidth}>
            <FormLabel htmlFor={withNamespace('courtCap')}>{t('court cap')}</FormLabel>
            <TextField
              id={withNamespace('courtCap')}
              name={withNamespace('courtCap')}
              value={pricingValues.courtCap}
              inputProps={{
                className: cx(styles.numberInput),
                type: 'number',
                min: 0,
                'data-testid': 'court-cap',
              }}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};

export default LtaMembershipPricing;
