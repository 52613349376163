import React from 'react';

import cx from 'classnames';
import InputLabel, { InputProps } from 'src/components/input-label/input-label';

import * as styles from './form-label.module.less';

const FormLabel: React.FC<InputProps> = ({ className, ...props }) => (
  <InputLabel
    spacing={{ margins: { md: 'top', xxs: 'bottom' } }}
    className={cx(className, styles.inputLabel)}
    {...props}
  />
);

export default FormLabel;
