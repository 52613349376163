import React from 'react';

import FormGroup from '@mui/material/FormGroup';
import { Field, getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import FormLabel from 'src/components/form-label/form-label';
import { CheckboxArray } from 'src/components/formik-fields/formik-fields';
import { useNamespace } from 'src/components/lta-membership-edit/helpers';
import { PaymentMethodAvailable } from 'src/graphql-types/lta-registration/globalTypes';

import * as styles from './lta-membership-payment.module.less';

interface LtaMembershipPaymentProp {
  namespace?: string;
}

const LtaMembershipPayment: React.FC<LtaMembershipPaymentProp> = ({ namespace = '' }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext();
  const withNamespace = useNamespace(namespace);

  const paymentValues = getIn(values, namespace);
  const paymentErrors = getIn(errors, namespace);

  const fieldName = withNamespace('paymentMethodAvailable');

  const paymentMethods = [
    {
      label: t('credit and debit card'),
      value: PaymentMethodAvailable.CARD,
    },
    {
      label: t('invoice'),
      value: PaymentMethodAvailable.INVOICE,
    },
    {
      label: t('direct debit'),
      value: PaymentMethodAvailable.DIRECTDEBIT,
    },
  ];

  const paymentMethodsChecked = {
    [PaymentMethodAvailable.CARD]: paymentValues?.paymentMethodAvailable?.includes(PaymentMethodAvailable.CARD),
    [PaymentMethodAvailable.INVOICE]: paymentValues?.paymentMethodAvailable?.includes(PaymentMethodAvailable.INVOICE),
    [PaymentMethodAvailable.DIRECTDEBIT]: paymentValues?.paymentMethodAvailable?.includes(
      PaymentMethodAvailable.DIRECTDEBIT,
    ),
  };

  const handlePaymentMethodChange = (checked) => {
    const paymentMethods: PaymentMethodAvailable[] = [];

    if (checked[PaymentMethodAvailable.CARD]) {
      paymentMethods.push(PaymentMethodAvailable.CARD);
    }

    if (checked[PaymentMethodAvailable.INVOICE]) {
      paymentMethods.push(PaymentMethodAvailable.INVOICE);
    }

    if (checked[PaymentMethodAvailable.DIRECTDEBIT]) {
      paymentMethods.push(PaymentMethodAvailable.DIRECTDEBIT);
    }

    setFieldValue(fieldName, paymentMethods);
  };

  return (
    <FormGroup className={styles.checkboxField}>
      <FormLabel htmlFor="paymentMethodAvailable" spacing={{ margins: { xxs: 'bottom' } }}>
        {t('payment methods')}
      </FormLabel>
      <Field
        id={fieldName}
        name={fieldName}
        component={CheckboxArray}
        options={paymentMethods}
        checked={paymentMethodsChecked}
        onCheckedChange={handlePaymentMethodChange}
      />
      {paymentErrors?.paymentMethodAvailable && (
        <FormErrorMessage>{paymentErrors?.paymentMethodAvailable}</FormErrorMessage>
      )}
    </FormGroup>
  );
};

export default LtaMembershipPayment;
