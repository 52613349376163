import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import EditMembership from 'src/components/lta-membership-edit/lta-membership-edit';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditMembershipPage = () => {
  return (
    <Router basepath="/lta-memberships/:membershipId">
      <EditMembershipRoute path="/edit" />
    </Router>
  );
};

interface EditMembershipRouteProps extends RouteComponentProps {
  membershipId?: string;
}

const EditMembershipRoute: React.FC<EditMembershipRouteProps> = ({ membershipId }) => {
  return (
    <Layout>
      <SEO title="Edit Membership" />
      <PrivateRoute>
        <EditMembership membershipId={membershipId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditMembershipPage;
