import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import { RouterLink } from 'src/components/button/link';
import {
  GET_MEMBERSHIP_PACKAGE_BY_ID,
  UPDATE_MEMBERSHIP_PACKAGE,
} from 'src/components/lta-membership-details/lta-membership-details-queries';
import LocalPanel from 'src/components/panel/panel';
import {
  membershipPackage as MembershipPackageResult,
  membershipPackageVariables as MembershipPackageVariables,
} from 'src/graphql-types/lta-registration/membershipPackage';
import {
  UpdateMembershipPackage,
  UpdateMembershipPackageVariables,
} from 'src/graphql-types/lta-registration/UpdateMembershipPackage';
import * as Yup from 'yup';

import { Spinner } from '@clubspark-react/clubspark-react-tools';

import { getDefaultMembershipPackage } from './helpers';
import LtaMembershipDates from './lta-membership-dates/lta-membership-dates';
import LtaMembershipDetails from './lta-membership-details/lta-membership-details';
import * as styles from './lta-membership-edit.module.less';
import LtaMembershipPayment from './lta-membership-payment/lta-membership-payment';
import LtaMembershipPricing from './lta-membership-pricing/lta-membership-pricing';
import { MembershipPackageFormValues } from './types';

interface LtaMembershipEditProps {
  membershipId?: string;
}

const CK_EDITOR_MAX_CHARS = 9999;

const useMembershipPackageValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    name: Yup.string().required(t('is required', { type: 'name' })),
    code: Yup.string().required(t('is required', { type: 'code' })),
    status: Yup.string().required(t('is required', { type: 'status' })),
    type: Yup.string().required(t('is required', { type: 'package type' })),
    description: Yup.string()
      .required(t('is required', { type: 'description' }))
      .max(CK_EDITOR_MAX_CHARS),
    benefits: Yup.string()
      .required(t('are required', { type: 'benefits' }))
      .max(CK_EDITOR_MAX_CHARS),
    confirmation: Yup.string().max(CK_EDITOR_MAX_CHARS),
    startDate: Yup.string().required(t('is required', { type: 'start date' })),
    endDate: Yup.string().required(t('is required', { type: 'end date' })),
    renewsOn: Yup.string().required(t('is required', { type: 'grace period end date' })),
    courtCost: Yup.number().required(t('is required', { type: 'cost per non-grass court' })),
    grassCourtCost: Yup.number().required(t('is required', { type: 'cost per grass court' })),
    courtCap: Yup.number().required(t('is required', { type: 'court cap' })),
    paymentMethodAvailable: Yup.array()
      .nullable()
      .required(t('are required', { type: 'payment methods' })),
  });
};

const LtaMembershipEdit: React.FC<LtaMembershipEditProps> = ({ membershipId }) => {
  const { t } = useTranslation();

  const {
    data: { membershipPackage } = {},
    loading,
    error,
  } = useQuery<MembershipPackageResult, MembershipPackageVariables>(GET_MEMBERSHIP_PACKAGE_BY_ID, {
    skip: !membershipId,
    variables: {
      id: membershipId || '',
    },
    fetchPolicy: 'no-cache',
  });

  const [updateMembershipPackage, { loading: savingMembershipPackage }] = useMutation<
    UpdateMembershipPackage,
    UpdateMembershipPackageVariables
  >(UPDATE_MEMBERSHIP_PACKAGE);

  const validationSchema = useMembershipPackageValidation();

  const existingMembership: MembershipPackageFormValues = Object.assign(
    {},
    getDefaultMembershipPackage(),
    membershipPackage,
  );

  const handleSubmit = ({
    id,
    terms,
    __typename,
    courtCost,
    grassCourtCost,
    courtCap,
    ...values
  }: MembershipPackageFormValues) => {
    if (!membershipId) {
      return;
    }

    updateMembershipPackage({
      variables: {
        id: membershipId,
        membershipPackageInput: {
          courtCap: Math.round(Number(courtCap)),
          courtCost: Math.round(Number(courtCost)),
          grassCourtCost: Math.round(Number(grassCourtCost)),
          ...values,
        },
      },
    }).then(() => navigate(`/lta-memberships/${membershipId}`));
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <APIErrorMessage error={error.message} />;
  }

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t('membership list'), to: '/lta-memberships' },
          {
            name: membershipPackage?.name || t('package name'),
            to: membershipId ? `/lta-memberships/${membershipId}` : '',
          },
          { name: t('edit package'), active: true },
        ]}
      />
      <h1 className={styles.mainHeading}>{t('edit membership package', { packageName: membershipPackage?.name })}</h1>
      <Formik
        initialValues={existingMembership}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <LtaMembershipEditForm {...props}>
              <Grid container className={styles.actions} spacing={2}>
                <Grid item>
                  <RouterLink
                    to={`/lta-memberships/${membershipId}`}
                    size="md"
                    type="button"
                    level="tertiary"
                    className={styles.cancelButton}
                  >
                    {t('cancel')}
                  </RouterLink>
                </Grid>
                <Grid item>
                  <Button type="submit" loading={savingMembershipPackage} disabled={savingMembershipPackage}>
                    {t('save membership')}
                  </Button>
                </Grid>
              </Grid>
            </LtaMembershipEditForm>
          </Form>
        )}
      </Formik>
    </>
  );
};

const LtaMembershipEditForm: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <LocalPanel spacing={{ margins: { md: 'top' } }}>
        <LocalPanel.Title className={styles.panelTitle}>
          {t('numbered header', { number: 1, text: 'membership details' })}
        </LocalPanel.Title>
        <Grid container direction="column" className={styles.detailsForm}>
          <LtaMembershipDetails />
        </Grid>
      </LocalPanel>
      <LocalPanel spacing={{ margins: { md: 'top' } }}>
        <LocalPanel.Title className={styles.panelTitle}>
          {t('numbered header', { number: 2, text: 'dates' })}
        </LocalPanel.Title>
        <LtaMembershipDates className={styles.datesForm} />
      </LocalPanel>
      <LocalPanel spacing={{ margins: { md: 'top' } }}>
        <LocalPanel.Title className={styles.panelTitle}>
          {t('numbered header', { number: 3, text: 'Pricing' })}
        </LocalPanel.Title>
        <LtaMembershipPricing />
      </LocalPanel>
      <LocalPanel spacing={{ margins: { md: 'top' } }}>
        <LocalPanel.Title className={styles.panelTitle}>
          {t('numbered header', { number: 4, text: 'payment settings' })}
        </LocalPanel.Title>
        <Grid container direction="column" className={styles.paymentForm}>
          <LtaMembershipPayment />
        </Grid>
      </LocalPanel>
      {children}
    </>
  );
};

export default LtaMembershipEdit;
