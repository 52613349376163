import { FormikProps } from 'formik';
import { ProcessingFeeType } from 'src/graphql-types/lta-registration/globalTypes';
import { membershipPackage_membershipPackage as MembershipPackage } from 'src/graphql-types/lta-registration/membershipPackage';

export enum MembershipPackageTermDiscountType {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED',
}

export interface MembershipPackageTermDiscount {
  name: string;
  startDate: string;
  endDate: string;
  type: MembershipPackageTermDiscountType;
  value: number;
}

export interface MembershipPackageTermPricing {
  pricingOption: ProcessingFeeType;
  courtCost: number | null;
  grassCourtCost: number | null;
  courtCap: number | null;
  discounts?: MembershipPackageTermDiscount[] | null;
}

export interface MembershipPackageTerm {
  name?: string;
  startDate?: string;
  endDate?: string;
  renewsOn?: string;
  status?: string;
  pricing: MembershipPackageTermPricing;
}

export enum MembershipPackageTermStatus {
  Public = 'PUBLIC',
  Private = 'HIDDEN',
}

export interface MembershipPackageFormValues extends Omit<MembershipPackage, '__typename'> {
  terms?: MembershipPackageTerm[];
}

export enum MembershipPackageTypes {
  Package_1 = 'Package 1',
  Package_2_Free = 'Package 2 - Free',
  Package_2_Paid = 'Package 2 - Paid',
  Package_2_A_Paid = 'Package 2a - Paid',
  Package_2_B = 'Package 2b',
  Package_3 = 'Package 3',
  Package_3A = 'Package 3a',
  Package_3_B = 'Package 3b',
  Package_4 = 'Package 4',
  Package_4_B = 'Package 4b',
  Package_5 = 'Package 5',
}
